@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  overflow-x: hidden;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  overflow-x: hidden;
}
img,
video {
  max-width: 100%;
  height: auto;
}
.video-wrap {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
video,
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
h1 {
  font-size: 45px;
  line-height: 65px;
  font-weight: 300;
}
.pr-34 {
  padding-right: 8.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.item-disabled {
  filter: grayscale(1);
}
.item-disabled div {
  background: white !important;
}
.item-disabled p {
  @apply !text-gray-500;
}
.content-wrap::-webkit-scrollbar {
  display: none;
}
.content-wrap {
  max-height: calc(100vh - 94px);
  overflow: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 3px grey; */
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #962cc6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #962cc6;
}
.bg-yellow {
  background-color: yellow;
}
.bg-green {
  background-color: #00b150;
}
.bg-blue {
  background-color: #0070c1;
}
.bg-red {
  background-color: #ff0000;
}
.bg-blue {
  background-color: #89d2f5;
}
.bg-goldenrod {
  background-color: #f9b33a;
}
.bg-darkred {
  background-color: #e52f77;
}
.theme-gradient {
  background-image: linear-gradient(
    to right,
    #c148e5,
    #b943e2,
    #b03edf,
    #a839dc,
    #9f34d9,
    #9735d8,
    #8e35d7,
    #8536d6,
    #7b3cd6,
    #7040d6,
    #6545d5,
    #5a48d4
  );
}
.btn-primary {
  @apply py-3 px-7 text-white rounded-xl font-medium tracking-wider;
  background: #962cc6;
}
.btn-primary:hover {
  @apply bg-purple-700;
}

.btn-primary:disabled {
  background: #a872c1;
}
.btn-secondary {
  @apply py-3 px-7 text-[#962cc6] bg-white rounded-xl font-medium tracking-wider;
}

/* banner css */
.banner-wrap {
  margin-top: -94px;
  background-image: url('./assets/images/landing/banner-img.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  min-height: 90vh;
}
/* .banner-content {
  margin-top: -94px;
} */
.banner-content h1 {
  font-size: 45px;
  line-height: 65px;
  font-weight: 300;
}

.banner-content h1 span {
  font-weight: bold;
}
.banner-content h4 {
  font-size: 20px;
  line-height: 30px;
}
/* .signup-wrap input {
  padding-right: 8.5rem;
} */
.signup-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.vl-wrap {
  background-image: url('./assets/images/landing/planet_mask.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.card h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 600;
}

.bridge-wrap {
  background-image: url('./assets/images/landing/Mask Group 8@2x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.three-card {
  bottom: -100px;
  position: relative;
}
.bridge-wrap h1 {
  font-size: 35px;
  line-height: 47px;
  text-align: center;
  font-weight: 300;
}
.bridge-wrap h1 span {
  font-weight: bold;
}
.bridge-wrap h4 {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

/* footer css */
.footer-heading {
  font-size: 18px;
  color: #bb6dff;
  margin-bottom: 20px;
}
.footer-nav ul li {
  padding: 8px 0;
}
.footer-nav ul li a {
  color: #fff;
}
.footer-nav ul li span {
  color: #fff;
}
/* .get-updates input{
  padding-right: 4rem;
} */
/* start page css */
.start-page,
.employment-8 {
  background-image: url('./assets/images/start-bg.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  overflow-x: hidden;
}
.start-page:before {
  @apply absolute top-0 bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-lshape.svg');
  left: -93px;
  height: 246px;
  width: 170px;
}
.start-page:after {
  @apply absolute bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-rshape.svg');
  right: -93px;
  height: 246px;
  width: 170px;
  top: 40%;
  z-index: -1;
}

.start-point .sp-heading {
  font-size: 35px;
  line-height: 50px;
}
.start-point h4 {
  font-size: 20px;
  line-height: 30px;
}

/* login-signup css */
.login-signup-bg {
  background-image: url('./assets/images/login-signup.svg');
  @apply bg-cover bg-top bg-no-repeat;
  min-height: 90vh;
}

.MuiTextField-root > .MuiFormLabel-root.Mui-focused,
.MuiInputLabel-shrink.MuiFormLabel-filled,
.MuiFormLabel-root.Mui-focused {
  color: #962cc6 !important;
  font-weight: 600;
}
/* for change border color*/
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #962cc6 !important;
}
.MuiInputBase-formControl {
  @apply !rounded-lg;
}
/* steps css */
.steps {
  @apply bg-contain bg-top bg-no-repeat;
}
.step-1,
.employment-3 {
  background-image: url('./assets/images/intake/step1/background.svg');
}
.step-1:before {
  @apply absolute top-0 bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-lshape.svg');
  left: -93px;
  height: 246px;
  width: 170px;
}
.step-1:after {
  @apply absolute bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-rshape.svg');
  right: -93px;
  height: 246px;
  width: 170px;
  bottom: 5%;
  z-index: -1;
}

/* Create a custom radio button */
.checkmark {
  @apply rounded-lg border border-gray-300 shadow  px-4 lg:px-6 h-full w-full absolute top-0 left-0 flex items-center;
}
.checkmark span {
  @apply break-all;
}
/* On mouse-over, add a background color and border color */
.radio-container:hover input ~ .checkmark {
  @apply bg-purple-200 border-purple-600;
}

/* When the radio button is checked, add a background color and border color */
.radio-container input:checked ~ .checkmark {
  @apply bg-purple-200 border-purple-600;
}
.radio-container input[type='radio']:checked ~ .checkmark:after {
  content: '';
  background-image: url('./assets/images/intake/step3/check.png');
  height: 25px;
  width: 25px;
  background-position: center;
  background-size: 15px;
  background-repeat: no-repeat;
  @apply absolute lg:right-6 lg:top-4 right-2 top-2;
}
.radio-container input[type='radio']:checked ~ .checkmark:before {
  content: '';
  background-image: linear-gradient(
    to top,
    #c148e5,
    #b943e2,
    #b03edf,
    #a839dc,
    #9f34d9,
    #9735d8,
    #8e35d7,
    #8536d6,
    #7b3cd6,
    #7040d6,
    #6545d5,
    #5a48d4
  );
  height: 25px;
  width: 25px;
  border-radius: 20px;
  background-position: center;
  @apply absolute lg:right-6 lg:top-4 right-2 top-2;
}

.step-2 {
  background-image: url('./assets/images/intake/step2/background.svg');
  background-size: 112%;
}

.step-3,
.employment-4,
.career-1 {
  background-image: url('./assets/images/intake/step3/background.svg');
  background-size: 112%;
}
.checkbox-checkmark {
  @apply rounded-lg border shadow px-4 lg:px-6 h-full w-full absolute top-0 left-0 flex items-center;
}
.checkbox-checkmark span {
  @apply break-all;
}
/* On mouse-over, add a background color and border color */
.checkbox-container:hover input[type='checkbox'] ~ .checkbox-checkmark {
  @apply bg-purple-200 border-purple-600;
}

/* When the radio button is checked, add a background color and border color */
.checkbox-container input[type='checkbox']:checked ~ .checkbox-checkmark {
  @apply bg-purple-200 border-purple-600;
}
.checkbox-container input[type='checkbox']:checked ~ .checkbox-checkmark:after {
  content: '';
  background-image: url('./assets/images/intake/step3/check.png');
  height: 25px;
  width: 25px;
  background-position: center;
  background-size: 15px;
  background-repeat: no-repeat;
  @apply absolute lg:right-6 lg:top-4 right-2 top-2;
}
.checkbox-container input[type='checkbox']:checked ~ .checkbox-checkmark:before {
  content: '';
  background-image: linear-gradient(
    to top,
    #c148e5,
    #b943e2,
    #b03edf,
    #a839dc,
    #9f34d9,
    #9735d8,
    #8e35d7,
    #8536d6,
    #7b3cd6,
    #7040d6,
    #6545d5,
    #5a48d4
  );
  /* background-color: red; */
  height: 25px;
  width: 25px;
  border-radius: 20px;
  background-position: center;
  @apply absolute lg:right-6 lg:top-4 right-2 top-2;
}

.step-5 {
  background-image: url('./assets/images/intake/step5/background.svg');
  background-size: 112%;
}
.step-6 {
  background-image: url('./assets/images/intake/step6/background.svg');
  background-size: 112%;
}
.step-7 {
  background-image: url('./assets/images/intake/step7/background.svg');
  background-size: 112%;
}
.step-4 {
  background-image: url('./assets/images/intake/step4/background.svg');
  background-size: 112%;
}

.rating-btns .checkmark {
  @apply rounded-lg  p-0 shadow-none h-[35px] w-[44px] absolute top-0 left-0 text-lg flex items-center justify-center text-gray-400 text-[15px];
}
.rating-btns .radio-container input:checked ~ .checkmark {
  @apply bg-purple-200 border-purple-600 text-black;
}
.rating-btns .radio-container input[type='radio']:checked ~ .checkmark:after {
  content: '';
  background-image: url('./assets/images/intake/step3/check.png');
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: 10px;
  background-repeat: no-repeat;
  position: absolute;
  right: -5px;
  top: -5px;
}
.rating-btns .radio-container input[type='radio']:checked ~ .checkmark:before {
  content: '';
  background-image: linear-gradient(
    to top,
    #c148e5,
    #b943e2,
    #b03edf,
    #a839dc,
    #9f34d9,
    #9735d8,
    #8e35d7,
    #8536d6,
    #7b3cd6,
    #7040d6,
    #6545d5,
    #5a48d4
  );
  /* background-color: red; */
  height: 16px;
  width: 16px;
  border-radius: 20px;
  background-position: center;
  position: absolute;
  right: -5px;
  top: -5px;
}

/* #outlined-select-age span {
  display: none;
}
#outlined-select-age:after {
  content: "Select";
} */
.employment-1 {
  background-image: url('./assets/images/intake/welcome/background.svg');
}
.char-img:before {
  background-image: url('./assets/images/intake/welcome/bal-g.svg');
  position: absolute;
  top: 98px;
  left: -110px;
  width: 33px;
  height: 106px;
  background-size: 100%;
  background-repeat: no-repeat;
  content: '';
}
.char-img:after {
  background-image: url('./assets/images/intake/welcome/bal-p.svg');
  position: absolute;
  top: 146px;
  right: -210px;
  width: 41px;
  height: 130px;
  background-size: 100%;
  background-repeat: no-repeat;
  content: '';
}
.employment-1:before {
  @apply absolute top-0 bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-lshape.svg');
  left: -93px;
  height: 246px;
  width: 170px;
}
.employment-1:after {
  @apply absolute bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-rshape.svg');
  right: -93px;
  height: 246px;
  width: 170px;
  top: 40%;
  z-index: -1;
}
/* skill assessment */
.employment-2 {
  background-image: url('./assets/images/intake/step9/background.svg');
  background-size: 112%;
}
ol > li::marker {
  font-weight: bold;
}
ul.list-disc > li::marker {
  @apply text-purple-600;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;

  background-color: white;
  color: black;
  border-radius: 12px;
  font-weight: 300;
  font-size: 15px;

  /* Position the tooltip */

  @apply shadow-lg p-7 w-[320px] lg:w-[420px] left-[unset] translate-x-[unset] -right-10 absolute bottom-full;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.employment-5 {
  background-size: 112%;
  background-image: url('./assets/images/intake/step12/background.svg');
  @apply overflow-visible;
}
.employment-6 {
  background-size: 112%;
  background-image: url('./assets/images/intake/step13/background.svg');
}

/* The container */
.cr-radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.cr-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.cr-checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #962cc6;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.cr-radio-container:hover input ~ .cr-checkmark {
  background-color: white;
}

/* When the radio button is checked, add a blue background */
.cr-radio-container input:checked ~ .cr-checkmark {
  border: 1px solid #962cc6;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.cr-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.cr-radio-container input:checked ~ .cr-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.cr-radio-container .cr-checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #962cc6;
}
.employment-7,
.employment-8 {
  background-size: 112%;
  background-image: url('./assets/images/intake/step14/background.svg');
}

.skills-1:before {
  @apply absolute top-0 bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-lshape.svg');
  left: -93px;
  height: 246px;
  width: 170px;
}
.skills-1:after {
  @apply absolute bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-rshape.svg');
  right: -93px;
  height: 246px;
  width: 170px;
  top: 40%;
  z-index: -1;
}

.skills-2,
.skills-3,
.skills-4,
.skills-5,
.skills-6,
.skills-7,
.skills-8,
.skills-9,
.skills-10,
.skills-11 {
  background-image: url('./assets/images/intake/step16/background.svg');
}

.skills-2::before,
.skills-3::before,
.skills-4::before,
.skills-5::before,
.skills-6::before,
.skills-7::before,
.skills-8::before,
.skills-9::before,
.skills-10::before,
.skills-11::before {
  @apply absolute top-0 bg-contain bg-top bg-no-repeat;
  content: '';
  background-image: url('./assets/images/sp-lshape.svg');
  left: -93px;
  height: 246px;
  width: 170px;
}

.experience-1,
.experience-2,
.experience-3,
.experience-4 {
  background-size: 112%;
  background-image: url('./assets/images/intake/step32/background.svg');
}
.career-1 {
  background-size: 112%;
  background-image: url('./assets/images/intake/step26/background.svg');
}
/*contact page css */
.contact-wrap:after,
.about-wrap:after {
  @apply absolute right-0 -z-10 top-20;
  content: '';
  width: 45%;
  height: 308px;
  background: transparent linear-gradient(109deg, #b943e2 0%, #5d2271 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 65px 0px 0px 51px;
}
.swot-step {
  background-size: 112%;
  background-image: url('./assets/images/swot/step1/bckground.svg');
  @apply overflow-visible;
}
.btn-primary:disabled {
  background: #a872c1;
}

@media screen and (min-width: 1024px) {
  .dsk-txt h2 {
    font-size: 1rem !important;
  }

  .dsk-txt ul li a.text-base.cst-text-base {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 991px) {
  .banner-wrap {
    background-image: none;
    min-height: 100%;
    margin: 0;
  }
  ul.navbar-nav {
    position: fixed;
    background: white;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    display: block;
    transition: 300ms all;
    @apply z-50;
  }
  ul.navbar-nav li {
    @apply py-3;
    padding: 10px 20px;
  }

  .navbar-toggle {
    @apply block;
  }
  .contact-wrap:after,
  .about-wrap:after {
    top: unset;
    bottom: 10rem;
    width: 100%;
  }
  .signup-wrap input::placeholder {
    font-size: 15px;
  }
  .character {
    display: none;
  }
  .chart-ui > div {
    width: 100%;
  }
  .chart-ui .interests-chart-img {
    max-width: 65%;
  }
}

@media screen and (max-width: 767px) {
  .mob-content-center {
    @apply block w-full text-center;
  }
}

@media screen and (max-width: 576px) {
  .label-width-wrapper .label-width {
    @apply w-full mb-3;
  }
}

/* .link:link,
.link:visited {
  color: -webkit-link;
  text-decoration: underline;
  word-break: break-all;
} */
.link {
  @apply text-blue-500 underline;
}
.pb-50 {
  padding-bottom: 10.5rem;
}
/* Jumpstart css start */
.jumpstart-wrap .content-section {
  background-image: linear-gradient(
    to bottom,
    #c148e5,
    #b943e2,
    #b03edf,
    #a839dc,
    #9f34d9,
    #9735d8,
    #8e35d7,
    #8536d6,
    #7b3cd6,
    #7040d6,
    #6545d5,
    #5a48d4
  );
}
.right-panel {
  transition: 300ms all;
  width: 100%;
  overflow-x: hidden;
}
.sidebar-nav {
  @apply max-w-[300px] pl-3 lg:fixed absolute top-[93px] z-10 -left-full;
  @apply lg:max-w-[280px] lg:static pr-3 py-5 overflow-auto w-full bg-white h-full lg:z-0;
  max-height: calc(100vh - 94px);
  transition: 300ms all;
}

.sidebar-enabled {
  left: 0;
  @apply border-r;
}
.sidebar-nav ul li {
  @apply text-sm font-semibold rounded-lg px-5 py-3;
}
.sidebar-nav ul li.active {
  @apply bg-purple-700 text-white;
}
.sidebar-nav
  ul
  li.active
  .page-progress
  span.MuiCircularProgress-root.MuiCircularProgress-determinate.MuiCircularProgress-colorPrimary.progress-color {
  color: #37fceb;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root {
  @apply bg-[#F5F5F5] mb-3 rounded-xl py-4 px-2;
}
.MuiAccordionSummary-content p.MuiTypography-root.MuiTypography-body1 {
  @apply text-lg font-medium;
  font-family: inherit;
}
.MuiAccordionSummary-content p.MuiTypography-root.MuiTypography-body1 {
  @apply !p-0;
}
p.MuiTypography-root.MuiTypography-body1 {
  font-family: inherit;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root
  p.MuiTypography-root.MuiTypography-body1 {
  @apply pl-14;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
  @apply pl-5;
}
.MuiAccordionSummary-expandIconWrapper {
  @apply h-8 w-8 border-2 border-purple-600 rounded-full flex items-center justify-center;
}
.MuiAccordionSummary-expandIconWrapper svg {
  fill: #962cc6;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.Mui-expanded {
  @apply bg-[#EFF2FB];
}
.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  @apply bg-purple-600;
}
.MuiAccordionSummary-expandIconWrapper.Mui-expanded svg {
  height: 2px;
  width: 14px;
  background: white;
}
.MuiAccordionSummary-expandIconWrapper {
  flex: none;
}
.custom-tab .MuiTabs-flexContainer {
  @apply mx-2 justify-between block lg:flex;
}
.custom-tab .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  @apply p-5 text-sm tracking-widest font-normal flex-1;
  font-family: inherit;
}
.custom-tab .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  @apply font-medium text-black;
}
.custom-tab span.MuiTabs-indicator {
  @apply bg-purple-600 h-[5px] rounded-t-xl;
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 55px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #a7a7a7;
  border-radius: 50%;
  padding: 0;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
  @apply border border-purple-600;
}

/* When the radio button is checked, add a blue background */
/* .custom-radio input:checked ~ .checkmark {
  @apply border border-purple-600;
} */

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  @apply block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  @apply bg-purple-600 rounded-full h-3 w-3 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  @apply border rounded;
  padding: 0;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #fafafa;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #7e22ce;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.left-shape,
.right-shape {
  background-color: #c4c4c4;
  height: 120px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-shape {
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}
.right-shape {
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
}
.left-content,
.right-content {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  background-color: white;
}
.left-content {
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}

.right-content {
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
  padding: 20px;
  padding-left: 60px;
}
.answer-active {
  @apply border-2 border-purple-600;
}

/* CarouselComponent css */

.carousel-container .carousel {
  @apply flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-container .slide {
  flex: 0 0 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel-container .active {
  opacity: 1;
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-container .prev,
.carousel-container .next {
  @apply w-10 h-10 bg-purple-600 rounded-full text-white absolute top-1/2 translate-y-1/2 border-none cursor-pointer text-xl z-10;
}

.carousel-container .prev {
  @apply left-3;
}

.carousel-container .next {
  @apply right-3;
}
.carousel-container .pagination {
  @apply flex justify-center mt-3;
}

.carousel-container .pagination-dot {
  @apply w-7 h-7 rounded-full flex items-center justify-center text-gray-500 cursor-pointer;
}

.carousel-container .pagination-dot.active {
  @apply border-2 border-purple-600  rounded-full text-black;
}
.emotional-chat.chat-bg {
  @apply min-h-[750px];
}
.emotional-chat.character-img {
  @apply w-96;
}
.chat-wrapper {
  @apply absolute left-1/2 -translate-x-1/2 top-0 h-full w-full lg:w-11/12 mx-auto flex justify-start lg:justify-center;
}
.chat-container {
  @apply text-sm lg:text-base w-full lg:ml-5 absolute lg:relative  my-10 md:my-20 lg:my-10 px-4 pb-16;
}
.jumpstart-systemchat.chat-container {
  @apply my-6 md:my-20 lg:my-10;
}
.continue-wrap {
  @apply bg-white rounded-2xl p-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 text-center;
}
.answer-options .continue-wrap,
.answer-options .continue-wrap2 {
  @apply mt-80 lg:mt-0;
}
.continue-wrap2 {
  @apply bg-white rounded-2xl p-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  w-3/4 lg:w-full text-center;
}
.answer-options {
  @apply w-full lg:w-3/4 float-right;
}
.ans-option {
  @apply text-sm lg:text-base bg-white rounded-2xl p-4 mb-3 hover:translate-x-4 transition-all flex items-center cursor-pointer;
}
.system-chat {
  @apply text-sm lg:text-base bg-white p-4 lg:p-6 rounded-2xl rounded-tl-none relative mb-5 w-1/2 lg:w-3/4 float-right lg:float-left mt-5 lg:mt-10;
}
.perception-chat.system-chat {
  @apply w-full lg:w-3/4;
}
.jumpstart-systemchat .system-chat {
  @apply w-8/12 lg:w-3/4;
}
.adaptchange-chat.system-chat {
  @apply w-3/4;
}
.system-chat:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0;
  border-color: rgb(255 255 255) transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: 0;
  left: -18px;
}
.user-chat {
  @apply bg-white p-6 rounded-2xl rounded-tr-none relative mb-5 mt-44 lg:mt-0 lg:w-3/4 float-right;
}
.user-chat:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0;
  border-color: rgb(255 255 255) transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: 0;
  right: -18px;
}

/* FlipCard css */

.flip-card {
  @apply h-[266px] w-[251px]  cursor-pointer;
  perspective: 1000px;
}

.flip-card-inner {
  @apply w-full h-full;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  @apply w-full h-full absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  @apply border border-t-8 border-t-purple-600 rounded-lg bg-white flex items-center justify-center;
}

.flip-card-back {
  @apply border bg-gray-200 rounded-lg flex items-center justify-center;
  transform: rotateY(180deg) scale(1.1);
}

.words-wrap .words-container span {
  @apply inline-block p-1 m-3 cursor-pointer;
}

.toggle-card-container {
  /* position: relative; */
}

.toggle-card-container .card {
  position: absolute;
  width: 400px;
  min-height: 207px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #000000;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
  @apply text-left px-8 py-10 ml-10 mr-10;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.seek-information th,
.seek-information td {
  @apply border px-7 py-6 text-left;
}
.seek-information th {
  background-color: #f5f5f5;
}
.attached {
  position: relative;
  left: 59px;
}
/* Dashboard css */
.dashboard {
  @apply bg-[#F5F7F9] py-7;
}
.dashboard-heading {
  @apply text-3xl font-semibold leading-10;
}
.info-card {
  @apply rounded-[20px] p-7;
}

.info-card .card-number {
  @apply text-6xl text-white;
}
.info-card .card-content {
  @apply text-lg text-white;
}

.course-card {
  @apply bg-white p-2 rounded-2xl shadow-sm relative;
}
.course-card .img-wrap {
  @apply h-[200px];
}
.course-card .img-wrap img {
  @apply h-full w-full object-cover rounded-t-xl;
}
.course-card .course-body {
  @apply px-4 py-6;
}
.course-card .course-type {
  @apply mb-3 text-blue-800;
}
.course-card .course-title {
  @apply text-2xl font-semibold leading-8 mb-2;
}
.course-card .course-content {
  @apply text-gray-500;
}
.course-card .course-action {
  @apply text-purple-700 p-4 flex items-center justify-between absolute bottom-0 w-full;
}

.c-swiper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.c-swiper-wrapper {
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.c-swiper-slide {
  padding: 10px;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.prev-button:disabled,
.next-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.prev-button {
  left: -50px;
}

.next-button {
  right: -50px;
}
.field-error {
  border: 1px solid red !important;
  border-radius: 0.5rem !important;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
.Mui-error {
  color: #d32f2f;
}
.MuiFormHelperText-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-view,
  .print-view * {
    visibility: visible;
  }
  .print-view {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white; /* Set the background color for the printed area */
  }
  /* Add specific styles for elements you want to preserve in print */
  .print-view .total-cards .bg-red,
  .print-view .total-cards .bg-yellow,
  .print-view .total-cards .bg-green,
  .print-view .total-cards .bg-blue {
    visibility: visible;
  }
}
.preferencesSwiper .swiper-button-next,
.preferencesSwiper .swiper-button-prev {
  bottom: 0;
  top: unset;
  @apply bg-purple-500 p-6;
}
.preferencesSwiper .swiper-button-next:after,
.preferencesSwiper .swiper-button-prev:after {
  @apply text-3xl font-bold text-white;
}
.preferencesTabContainer .MuiTabs-flexContainer {
  gap: 10px;
}
.preferencesTabContainer .MuiTabs-flexContainer .preferencesTab {
  background-color: #57bfe7;
}
.preferencesTabContainer .MuiTabs-flexContainer .preferencesTab.Mui-selected {
  @apply bg-blue-600;
}
.bg-cool-blue {
  background-color: #0188d3 !important;
}
.bg-sunshine-yellow {
  background-color: #fcca4f !important;
}
.bg-earth-green {
  background-color: #8ba40a !important;
}
.bg-fiery-red {
  background-color: #b73434 !important;
}

.logo {
  max-width: 75%;
  max-height: 20%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  @apply text-purple-500;
  transform: scale(0.5);
  font-weight: bolder;
}

.course-card .course-body {
  min-height: 360px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.course-card .course-action {
  padding-left: 2rem;
  padding-right: 2rem;
}

.dashboard-course {
  cursor: not-allowed;
}

/* pop-up css */

.cstm-popup {
  max-width: 90%;
  margin: 0 auto;
}
.cstm-popup-fg {
  max-width: 90%;
  margin: 0 auto;
}

/* Loader css */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 1000;
  color: #962cc6;
}

.blur {
  filter: blur(1px);
}

/* download button css */

.pos-dwnld-btn {
  position: fixed;
  bottom: 2.5rem;
  left: 1.5rem;
  border-radius: 50%;
  height: 55px;
}
.swot-card {
  @apply text-center border border-dashed rounded-3xl p-5 min-h-[480px] relative;
}

.payment-box {
  @apply px-5 py-5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.upgrade-wrap {
  background-image: url('./assets/images/upgrade/background.svg');
  background-size: 100%;
  background-repeat: no-repeat;
}

.imgwrapper {
  @apply absolute;
  @apply top-0;
  @apply left-2/4;
  @apply -translate-x-2/4 -translate-y-2/4;
}

ul.cstm-unordered-list li {
  @apply text-sm;
  @apply font-semibold;
}

/*  forget password popup */
/* popup.css */

.cstm-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  outline: none;
}
.cstm-popup-fg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  outline: none;
}

.cstm-popup h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.cstm-popup .MuiTextField-root {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.cstm-popup .btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.cstm-popup .btn-container .MuiButton-root {
  margin-left: 10px;
}

.cstm-popup .close-btn {
  position: absolute;
  right: 16px;
  top: 16px;
}

/* Generic table css */
.module-header {
  background-color: #f0f0f0; /* Light gray background for module headers */
  text-align: center;
  border: 2px solid #d1d1d1;
}
.module1-bg {
  background-color: rgb(232, 245, 232);
  font-weight: 900 !important;
}
.module2-bg {
  background-color: rgb(203, 214, 230);
  font-weight: 900 !important;
}
.data-cell {
  padding: 8px;
  border-right: 1px solid #e0e0e0; /* Vertical borders between cells */
}

.data-cell:last-child {
  border-right: none; /* Remove border from the last cell in the row */
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Ensure horizontal scroll if table overflows */
}

.table-head-cell {
  padding: 12px;
}

.sticky-first-column {
  position: sticky;
  left: 0;
  background-color: #f0f0f0; /* Matches the table background */
  z-index: 1; /* Ensures the first column stays on top when scrolling */
  box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.1); /* Optional shadow effect */
  border-bottom: 2px solid #d1d1d1;
  border-right: 2px solid #d1d1d1;
  /* border: 2px solid #d1d1d1; */
}
